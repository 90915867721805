<script setup lang="ts">
import { getProduct } from "@shopware-pwa/api-client";
import DepositProductPrompt from "./DepositProductPrompt.vue";
import CrossSellPrompt from "./CrossSellPrompt.vue";
import ContinueShoppingPrompt from "./ContinueShoppingPrompt.vue";
import PickupLocationPrompt from "../shared/PickupLocationPrompt.vue";
import { generateCriteria, type BaseCrossSellChannel } from "~/helpers/productHelpers";
import { SHIPPING_METHODS, SHIPPING_METHODS_LIST } from "~/lib/constants";

const props = defineProps<
	| {
			product: KippieProduct;
			productIdProp?: string;
	  }
	| {
			product?: KippieProduct;
			productIdProp: string;
	  }
>();

const { productIdProp } = props as any;
const product = toRef(props.product as KippieProduct);

const { apiInstance } = useShopwareContext();
const { addProduct, selectedPickupLocation } = useCart();

const { onAddToCart: gtagOnAddToCart } = useGtagEvents();
const { addToCart: pinterestAddToCart } = usePinterestTrackingEvents();
const { addedToCartObjectIDs } = useAlgoliaSearchInsights();
const snackbar = useSnackbar();
const { selectedShippingMethod, setShippingMethod } = useCheckout();

const productId = computed(() => {
	return (product.value.id || productIdProp) as string;
});

const drawerProvider = useDrawer();

const currentShippingMethod = computed(() => {
	return SHIPPING_METHODS_LIST.find((method) => method.id === selectedShippingMethod.value?.id);
});

const {
	isLoading,
	productAssociations,
	loadAssociations
} = useProductAssociations(product as ComputedRef<KippieProduct>, { associationContext: "cross-selling" });

const { data: addToCartProduct, execute: loadProduct } = useCachedAsyncData(
	`shopware:product:${productId.value}`,
	async () => {
		if (product) return product.value as KippieProduct;

		const productResponse = await getProduct(
			productId.value,
			{
				...generateCriteria()
			},
			apiInstance
		);

		return productResponse.product as KippieProduct;
	},
	{ immediate: false, lazy: true }
);

const promptDepositProduct = async (productId: string) => {
	await drawerProvider.showDrawer(DepositProductPrompt, {
		drawerTitle: "Elektrische pan erbij?",
		productId
	});
};

const promptCrossSell = async (crossSellChannel: BaseCrossSellChannel) => {
	await drawerProvider.showDrawer(CrossSellPrompt, {
		drawerTitle: crossSellChannel.name,
		isLoading: isLoading.value,
		productAssociations: productAssociations.value,
	});
};

const promptContinueShopping = async () => {
	await drawerProvider.showDrawer(ContinueShoppingPrompt, {
		drawerTitle: "Het artikel is toegevoegd"
	});
};

const onAddToCart = async (e: Event, selectPickupLocation?: boolean) => {
	e.preventDefault();

	await loadProduct();

	if (!addToCartProduct.value) {
		return snackbar.open({
			content: "Er is iets misgegaan, probeer het later opnieuw.",
			icon: "info",
			iconColor: "red"
		});
	}

	if (selectPickupLocation && currentShippingMethod.value?.type !== "pickup") {
		setShippingMethod({ id: SHIPPING_METHODS.pickup.id });
	}

	addProduct(addToCartProduct.value);
	pinterestAddToCart(addToCartProduct.value);

	gtagOnAddToCart(addToCartProduct.value, 1);
	addedToCartObjectIDs([addToCartProduct.value.id], [addToCartProduct.value]);

	if (!!addToCartProduct.value.crossSellings && addToCartProduct.value.crossSellings?.length > 0) {
		loadAssociations({
			method: "post",
			searchParams: {
				associations: {
					cover: {
						associations: {
							media: {}
						}
					}
				}
			}
		});
	}

	if (addToCartProduct.value.customFields.kippie_deposit_fields_deposit_product) {
		await promptDepositProduct(addToCartProduct.value.customFields.kippie_deposit_fields_deposit_product);
	}

	if (!!addToCartProduct.value.crossSellings && addToCartProduct.value.crossSellings.length > 0) {
		await promptCrossSell(addToCartProduct.value.crossSellings[0] as unknown as BaseCrossSellChannel);
	}

	if (selectPickupLocation && !selectedPickupLocation.value) {
		await drawerProvider.showDrawer(PickupLocationPrompt, {
			drawerTitle: "Kies een afhaallocatie"
		});
	}

	promptContinueShopping();

	snackbar.open({
		content: "Product toegevoegd aan winkelmandje",
		icon: "shopping-bag",
		iconColor: "white"
	});
};
</script>

<template>
	<slot :on-add-to-cart="onAddToCart" />
</template>
